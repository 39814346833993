<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12">
      <v-card
        v-if="transactionData"
        class="mb-10"
      >
        <v-fab-transition>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="secondary"
                dark
                absolute
                small
                top
                right
                fab
                class="mb-4 me-3"
                v-on="on"
                @click="
                  $router.push({
                    name: 'views-order-history-list',
                    params: { pageParams: pageData, filterParams: filterData },
                  })
                "
              >
                <v-icon>
                  {{ icons.mdiTableArrowLeft }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ t('tooltip.back_table') }}</span>
          </v-tooltip>
        </v-fab-transition>

        <v-card-text class="py-7 px-8">
          <v-row class="d-flex justify-space-between">
            <v-col
              cols="12"
              md="3"
            >
              <div class="d-flex align-center">
                <v-avatar
                  :color="transactionData.facility_avatar ? '' : 'primary'"
                  :class="transactionData.facility_avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="64"
                >
                  <v-img
                    v-if="transactionData.facility_avatar"
                    :src="transactionData.facility_avatar"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-regular"
                  >{{ avatarText(transactionData.facility_name) }}</span>
                </v-avatar>

                <h2 class="font-weight-medium ml-2">
                  {{ transactionData.facility_name }}
                </h2>
              </div>
            </v-col>

            <v-col
              class="d-flex align-center"
              cols="12"
              md="4"
            >
              <div class="d-flex align-center">
                <h2 class="font-weight-medium">
                  {{ t('transactions.transaction') }}
                </h2>
              </div>
            </v-col>

            <v-col
              v-if="transactionData.payment"
              class="d-flex align-center"
              cols="12"
              md="5"
            >
              <div class="d-flex align-center">
                <h2 class="font-weight-medium">
                  {{ t('payment_methods.payment') }}
                </h2>
              </div>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-space-between">
            <v-col
              class="d-flex justify-space-between"
              cols="12"
              md="3"
            >
              <div>
                <table>
                  <tr>
                    <td>
                      {{ transactionData.facility_short_address }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ transactionData.facility_phone }}
                    </td>
                  </tr>
                </table>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <div>
                <table>
                  <tr>
                    <td class="pe-6">
                      ID:
                    </td>
                    <td>
                      #{{ transactionData.id }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('transactions.date_issued') }}:
                    </td>
                    <td>
                      {{ transactionData.transaction_date }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('transactions.due_date') }}:
                    </td>
                    <td>
                      {{ transactionData.due_date }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('status.status') }}:
                    </td>
                    <td>
                      {{ transactionData.status_str }}
                    </td>
                  </tr>
                </table>
              </div>
            </v-col>

            <v-col
              v-if="transactionData.payment"
              cols="12"
              md="5"
            >
              <div>
                <table>
                  <tr>
                    <td class="pe-6">
                      ID:
                    </td>
                    <td>
                      #{{ transactionData.payment.id }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.brand') }}:
                    </td>
                    <td>
                      {{ transactionData.payment.payment_method.brand }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.card_number') }}:
                    </td>
                    <td>
                      {{ transactionData.payment.payment_method.number }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.name_card') }}:
                    </td>
                    <td>
                      {{ transactionData.payment.payment_method.owner_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.billing_address') }}:
                    </td>
                    <td>
                      {{ transactionData.payment.payment_method.billing_address }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.billing_phone') }}:
                    </td>
                    <td>
                      {{ transactionData.payment.payment_method.billing_phone }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.billing_email') }}:
                    </td>
                    <td>
                      {{ transactionData.payment.payment_method.billing_email }}
                    </td>
                  </tr>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="py-7 px-8">
          <div class="d-flex justify-end mb-2">
            <v-btn
              v-if="hasPermission(77)"
              class="left-align"
              color="secondary"
              x-small
              @click="generateReport()"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiPrinter }}
              </v-icon>
              <span>{{ t('tooltip.print') }}</span>
            </v-btn>
          </div>

          <v-simple-table
            v-if="transactionDataComputed"
            class="purchased-items-table-bordered"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>
                    {{ t('videos.video').toUpperCase() }}
                  </th>
                  <th>
                    {{ t('sports.sport').toUpperCase() }}
                  </th>
                  <th>
                    {{ t('transactions.start_time').toUpperCase() }}
                  </th>
                  <th>
                    {{ t('transactions.end_time').toUpperCase() }}
                  </th>
                  <th>
                    {{ t('transactions.price').toUpperCase() }}
                  </th>
                  <th class="text-center">
                    {{ t('tooltip.actions').toUpperCase() }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in transactionDataComputed"
                  :key="item.id"
                >
                  <td class="text-no-wrap">
                    {{ item.id }}
                  </td>
                  <td class="text-no-wrap">
                    {{ item.field_name }} {{ item.video_date }}
                  </td>
                  <td class="text-no-wrap">
                    {{ item.sport_name }}
                  </td>
                  <td class="text-no-wrap">
                    {{ item.start_time }}
                  </td>
                  <td>
                    {{ item.end_time }}
                  </td>
                  <td class="text-no-wrap">
                    {{ transactionData && transactionData.subtotal ? formatCurrency(transactionData.subtotal) : '$0.00' }}
                  </td>
                  <td class="text-no-wrap">
                    <div class="text-center d-flex align-center justify-space-around">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="transactionData.video"
                            color="secondary"
                            dark
                            icon
                            v-bind="attrs"
                            :to="{
                              name: 'views-order-history-video-preview',
                              params: {
                                id: transactionData.video.id || 0,
                                dataParams: transactionData.video,
                                dataTransaction: transactionData,
                              },
                            }"
                            v-on="on"
                          >
                            <v-icon size="18">
                              {{ icons.mdiVideoOutline }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ t('tooltip.view_video') }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-text class="py-7 px-8">
          <v-row justify="space-between">
            <v-col
              cols="12"
              md="3"
            >
              <span class="font-weight-semibold test-body-1">
                {{ t('transactions.payment_agent') }}:
              </span>
              <span class="font-weight-light test-body-1">
                {{ transactionData.user ? transactionData.user.fullname : transactionData.user_app ? transactionData.user_app.fullname : '' }}
              </span>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <div class="mb-2 mb-sm-0 d-flex">
                <span v-if="transactionData.note">{{ `${t('transactions.note')}:  ${transactionData.note}` }}</span>
              </div>
              <div>
                <table
                  v-if="transactionData.status !== 'D'"
                  class="w-100"
                >
                  <tr>
                    <td class="pe-16 font-weight-light">
                      Subtotal:
                    </td>
                    <th
                      class="font-weight-light"
                      :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                    >
                      {{ transactionData.subtotal ? formatCurrency(transactionData.subtotal) : '$0.00' }}
                    </th>
                  </tr>

                  <tr v-if="transactionData.discount && transactionData.discount !== 0">
                    <td class="pe-16 font-weight-light">
                      {{ t('video_details.discount') }}:
                    </td>
                    <th
                      class="font-weight-light"
                      :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                    >
                      {{ transactionData.discount ? `(${formatCurrency(transactionData.discount)})` : '($0.00)' }}
                    </th>
                  </tr>

                  <tr>
                    <td class="pe-16 font-weight-light">
                      {{ t('video_details.tax') }}:
                    </td>
                    <th
                      class="font-weight-light"
                      :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                    >
                      {{ transactionData.tax ? formatCurrency(transactionData.tax) : '$0.00' }}
                    </th>
                  </tr>

                  <tr>
                    <td class="pe-16 font-weight-light">
                      {{ t('video_details.comission') }}:
                    </td>
                    <th
                      class="font-weight-light"
                      :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                    >
                      {{ transactionData.comission ? `(${formatCurrency(transactionData.comission)})` : '($0.00)' }}
                    </th>
                  </tr>
                </table>
                <v-divider class="mt-4 mb-3"></v-divider>
                <table class="w-full">
                  <tr>
                    <td class="pe-16 font-weight-light">
                      Total:
                    </td>
                    <th
                      class="font-weight-semibold"
                      :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                    >
                      {{ transactionData && transactionData.amount ? formatCurrency(transactionData.amount) : '$0.00' }}
                    </th>
                  </tr>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- <v-col
      cols="12"
      class="mt-5"
    >
      <v-card v-if="transactionData">
        <v-fab-transition>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="secondary"
                dark
                absolute
                small
                top
                right
                fab
                class="mb-4 me-3"
                v-on="on"
                @click="
                  $router.push({
                    name: 'views-order-history-list',
                    params: { pageParams: pageData, filterParams: filterData },
                  })
                "
              >
                <v-icon>
                  {{ icons.mdiTableArrowLeft }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ t('tooltip.back_table') }}</span>
          </v-tooltip>
        </v-fab-transition>

        <v-card-text class="py-7 px-8">
          <v-row class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row pa-4 pa-sm-0">
            <v-col class="mb-8 mb-sm-0 pa-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="appLogo"
                  max-height="50px"
                  max-width="50px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span
                  class="text--primary font-weight-bold text-xl"
                  style="color: white !important; font-family: 'Orbitron'"
                >
                  {{ appName }}
                </span>
              </div>
              <span
                class="d-block"
              >{{ t('transactions.visit_us') }}:
                <a
                  style="color: #ff3232"
                  href="https://fanaty.com/"
                >https://fanaty.com/ </a>
              </span>
              <span
                class="d-block"
              >{{ t('transactions.contact_us') }}:
                <a
                  style="color: #ff3232"
                  href="https://fanaty.com/"
                >support@fanaty.com</a></span>
            </v-col>
            <v-col class="mb-8 mb-sm-0 pa-0">
              <p class="font-weight-medium text-xl text--primary mb-4 d-flex align-center">
                {{ t('transactions.transaction') }} #{{ transactionData.id }}
                <v-chip
                  small
                  :color="resolveClientAvatarVariant(transactionData.status)"
                  :class="`${resolveClientAvatarVariant(transactionData.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize ml-2"
                >
                  {{ transactionData.status_str }}
                </v-chip>
              </p>
              <p class="mb-2">
                <span>{{ t('transactions.date_issued') }}: </span>
                <span class="font-weight-semibold">{{ transactionData.transaction_date }}</span>
              </p>
              <p class="mb-2">
                <span>{{ t('transactions.due_date') }}: </span>
                <span class="font-weight-semibold">{{ transactionData.due_date }}</span>
              </p>
            </v-col>

            <v-col class="mb-0 mb-sm-0 pa-0 d-flex align-center justify-lg-space-between">
              <v-row class="justify-center">
                <v-col
                  v-if="hasPermission(77)"
                  cols="12"
                  md="6"
                  sm="12"
                >
                  <v-btn
                    color="secondary"
                    block
                    @click="generateReport()"
                  >
                    <v-icon
                      class="me-2"
                      left
                    >
                      {{ icons.mdiPrinter }}
                    </v-icon>
                    <span>{{ t('tooltip.print') }}</span>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                >
                  <v-btn
                    v-if="transactionData.status && transactionData.status === 'N'"
                    color="secondary"
                    block
                    @click="isAddPaymentSidebarActive = true"
                  >
                    <v-icon
                      class="me-2"
                      left
                    >
                      {{ icons.mdiCurrencyUsd }}
                    </v-icon>
                    <span>{{ t('transactions.pay_now') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="py-7 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div
              v-if="transactionData.payment"
              class="mb-8 mb-sm-0"
            >
              <p class="font-weight-semibold payment-details-header">
                {{ t('transactions.invoice_to') }}:
              </p>
              <p class="mb-1">
                {{ transactionData.facility_name }}
              </p>
              <p class="mb-1">
                {{ transactionData.facility_email }}
              </p>
              <p class="mb-1">
                {{ transactionData.facility_phone }}
              </p>
            </div>
            <div v-if="transactionData.payment">
              <p class="font-weight-medium d-flex align-center payment-details-header">
                {{ t('payment_methods.payment') }} #{{ transactionData.payment.id }}
                <v-chip
                  v-if="transactionData.status === 'C'"
                  small
                  :color="resolveClientAvatarVariant(transactionData.status)"
                  :class="`${resolveClientAvatarVariant(transactionData.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize ml-2"
                >
                  {{ transactionData.refunded }}
                </v-chip>
              </p>
              <table>
                <tr>
                  <td class="pe-6">
                    {{ t('payment_methods.brand') }}:
                  </td>
                  <td>
                    {{ transactionData.payment.payment_method.brand }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('payment_methods.card_number') }}:
                  </td>
                  <td>
                    {{ transactionData.payment.payment_method.number }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('payment_methods.name_card') }}:
                  </td>
                  <td>
                    {{ transactionData.payment.payment_method.owner_name }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('payment_methods.billing_address') }}:
                  </td>
                  <td>
                    {{ transactionData.payment.payment_method.billing_address }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('payment_methods.billing_phone') }}:
                  </td>
                  <td>
                    {{ transactionData.payment.payment_method.billing_phone }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('payment_methods.billing_email') }}:
                  </td>
                  <td>
                    {{ transactionData.payment.payment_method.billing_email }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-simple-table
          v-if="transactionDataComputed"
          class="purchased-items-table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>ID</th>
                <th>
                  {{ t('videos.video').toUpperCase() }}
                </th>
                <th>
                  {{ t('sports.sport').toUpperCase() }}
                </th>
                <th>
                  {{ t('transactions.start_time').toUpperCase() }}
                </th>
                <th>
                  {{ t('transactions.end_time').toUpperCase() }}
                </th>
                <th>
                  {{ t('transactions.price').toUpperCase() }}
                </th>
                <th class="text-center">
                  {{ t('tooltip.actions').toUpperCase() }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in transactionDataComputed"
                :key="item.id"
              >
                <td class="text-no-wrap">
                  {{ item.id }}
                </td>
                <td class="text-no-wrap">
                  {{ item.field_name }} {{ item.video_date }}
                </td>
                <td class="text-no-wrap">
                  {{ item.sport_name }}
                </td>
                <td class="text-no-wrap">
                  {{ item.start_time }}
                </td>
                <td>
                  {{ item.end_time }}
                </td>
                <td class="text-no-wrap">
                  {{ transactionData && transactionData.subtotal ? formatCurrency(transactionData.subtotal) : '$0.00' }}
                </td>
                <td class="text-no-wrap">
                  <div class="text-center d-flex align-center justify-space-around">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="transactionData.video"
                          color="secondary"
                          dark
                          icon
                          v-bind="attrs"
                          :to="{
                            name: 'views-order-history-video-preview',
                            params: {
                              id: transactionData.video.id || 0,
                              dataParams: transactionData.video,
                              dataTransaction: transactionData,
                            },
                          }"
                          v-on="on"
                        >
                          <v-icon size="18">
                            {{ icons.mdiVideoOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ t('tooltip.view_video') }}</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-text class="py-7 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <div class="mb-2 mb-sm-0">
              <p
                v-if="transactionData.user"
                class="mb-1"
              >
                <span class="font-weight-semibold">{{ t('users.user') }}:</span>
                <span>{{ transactionData.user.fullname }}</span>
              </p>
              <p>{{ t('transactions.thanks_buy') }}</p>
            </div>
            <div>
              <table>
                <tr>
                  <td class="pe-16">
                    Subtotal:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ transactionData.subtotal ? formatCurrency(transactionData.subtotal) : '$0.00' }}
                  </th>
                </tr>

                <tr v-if="transactionData.discount && transactionData.discount !== 0">
                  <td class="pe-16 success--text font-weight-bold">
                    {{ t('video_details.discount') }}:
                  </td>
                  <th
                    :class="
                      $vuetify.rtl
                        ? 'text-left success--text font-weight-bold'
                        : 'text-right  success--text font-weight-bold'
                    "
                  >
                    {{ transactionData.discount ? `(${formatCurrency(transactionData.discount)})` : '($0.00)' }}
                  </th>
                </tr>

                <tr>
                  <td class="pe-16">
                    {{ t('video_details.tax') }}:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ transactionData.tax ? formatCurrency(transactionData.tax) : '$0.00' }}
                  </th>
                </tr>

                <tr>
                  <td class="pe-16 success--text font-weight-bold">
                    {{ t('video_details.comission') }}:
                  </td>
                  <th
                    :class="
                      $vuetify.rtl
                        ? 'text-left success--text font-weight-bold'
                        : 'text-right  success--text font-weight-bold'
                    "
                  >
                    {{ transactionData.comission ? `(${formatCurrency(transactionData.comission)})` : '($0.00)' }}
                  </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Total:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ transactionData && transactionData.amount ? formatCurrency(transactionData.amount) : '$0.00' }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col> -->

    <order-history-sidebar-add-payment
      v-model="isAddPaymentSidebarActive"
      :transaction-selected="[transactionData.id]"
      :amount-transaction-selected="transactionData && transactionData.amount ? transactionData.amount : 0"
      :group-id="groupId"
      @refresh="initData"
    ></order-history-sidebar-add-payment>

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="
        transactionData.payment
          ? `Payment #${transactionData.payment.id} ${dateNow}`
          : `Transaction #${transactionData.id} ${dateNow}`
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <order-history-report
          :data-params="transactionData"
          :payment-params="transactionDataComputed"
        ></order-history-report>
      </section>
    </vue-html2pdf>
  </v-row>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { mdiCurrencyUsd, mdiVideoOutline, mdiTableArrowLeft, mdiPrinter } from '@mdi/js'
import { getTransaction } from '@api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useRouter, formatCurrency } from '@core/utils'
import { info, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@core/utils/useSelectOptions'

// composition function
import VueHtml2pdf from 'vue-html2pdf'
import OrderHistoryReport from '@/templates/reports/OrderHistoryReport.vue'
import OrderHistorySidebarAddPayment from '../OrderHistorySidebarAddPayment.vue'
import useOrderHistoryList from '../order-history-list/useOrderHistoryList'

export default {
  components: {
    OrderHistorySidebarAddPayment,
    OrderHistoryReport,
    VueHtml2pdf,
  },
  setup() {
    const { route, router } = useRouter()
    const { hasPermission } = usePermissions()
    const { t } = useUtils()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()
    const { resolveClientAvatarVariant, resolveColor } = useOrderHistoryList()

    const dataId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const transactionData = ref({
      amount: null,
      discount: null,
      due_date: null,
      facility_avatar: null,
      facility_email: null,
      facility_id: null,
      facility_name: null,
      facility_phone: null,
      facility_short_address: null,
      id: null,
      paid: null,
      payment: null,
      status: null,
      status_str: null,
      subtotal: null,
      transaction_date: null,
      user: {
        fullname: null,
      },
      video: {
        facility_name: null,
      },
    })
    const isAddPaymentSidebarActive = ref(false)
    const html2Pdf = ref(null)
    const dateNow = ref(new Date().toISOString().substring(0, 10))
    const transactionDataComputed = computed(() => {
      if (transactionData.value && transactionData.value.video) return [transactionData.value.video]

      return null
    })
    const purchasedProducts = ref([])
    const groupId = ref(null)

    const generateReport = () => {
      html2Pdf.value.generatePdf()
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
    }

    const goBack = () => {
      router.replace({
        name: 'views-order-history-list',
      })
    }

    const initData = async () => {
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      dataId.value = route.value.params.id
      if (dataId.value) {
        const resp = await getTransaction(dataId.value)
        if (resp.ok) {
          transactionData.value = resp.data
          groupId.value = transactionData.value.group_id
        } else goBack()
      } else goBack()
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      await initData()
    })

    return {
      // data
      transactionData,
      purchasedProducts,
      groupId,
      pageData,
      filterData,
      html2Pdf,
      dateNow,

      // computed
      transactionDataComputed,

      // methods
      formatCurrency,
      resolveClientAvatarVariant,
      resolveColor,
      generateReport,
      beforeDownload,
      hasDownloaded,
      hasPermission,
      initData,
      avatarText,

      // i18n
      t,

      // drawer
      isAddPaymentSidebarActive,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiCurrencyUsd,
        mdiVideoOutline,
        mdiTableArrowLeft,
        mdiPrinter,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
